/* Style for the container */
.home-container {
  padding: 1em;
}

/* Heading styles */
.heading {
  font-size: 24px;
  color: #333;
  text-align: center;
}
.dd-language-select {
  width: 100%;
  padding-top: 12px;
  margin-bottom: 0.3rem;
  flex: 1;
  display: flex;
  justify-content: center;
}

.topbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 20px;
}

.input-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 40vw;
}
#learner-input {
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

#learner-input :focus {
  border-color: #54889b;
  box-shadow: 0 0 5px rgba(84, 136, 155, 0.5);
}

/* Style for the back button */
.back-btn {
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  display: block;
  margin: 10px auto;
}

.back-btn:hover {
  background-color: #e53935;
}

.home-heading {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  color: #000000;
  background: linear-gradient(90deg, rgb(255, 255, 255), rgb(207, 203, 203));
  padding: 0.3rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.delete-selected-btn:hover {
  background-color: darkred;
}

/* Delete Icon */
.delete-icon {
  cursor: pointer;
  color: red;
  transition: color 0.3s ease;
}

.delete-icon:hover {
  color: darkred;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.select-all-container label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.learner-count {
  position: absolute;
  top: 35%;
  right: 20px;
  padding: 15px;

  text-align: center;
  color: #000000;
  transition: transform 0.3s ease;
  z-index: 10;
}

.learner-count:hover {
  transform: translateY(-5px);
}

.learner-count p {
  margin: 8px 0;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.learner-count p span {
  font-size: 22px;
  font-weight: 900;
  color: #ffe600;
}

.continue-btn-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

/* Add Learner button styling */
#add-learner-btn {
  background-color: #10b981; /* Same green color as Start learning */
  color: white;
  padding: 0.5rem 1rem; /* Adjust padding for consistent size */
  border-radius: 0.375rem; /* Rounded corners */
  font-size: 1rem; /* Font size */
  font-weight: 500; /* Font weight */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

#add-learner-btn:hover {
  background-color: #059669; /* Darker green on hover */
}

/* Styles for smaller screens */
@media (max-width: 768px) {
  .topbar {
    flex-direction: column;
    align-items: stretch;
  }

  .input-container,
  .dd-language-select,
  .continue-btn-container {
    width: 100%;
    justify-content: center;
  }

  .continue-btn-container button {
    width: 100%; /* Make the button full-width on mobile */
  }
}

/* Default styles for larger screens */
.topbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.input-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.continue-btn-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .topbar {
    flex-direction: column;
    align-items: stretch;
  }

  .input-container,
  .continue-btn-container {
    width: 100%;
    justify-content: center;
  }

  .continue-btn-container button {
    width: 100%; /* Make the button full-width on mobile */
  }
}
