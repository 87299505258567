.dropdown-container {
  position: relative; display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.dropdown-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.dropdown-button {
  width: 100%;
  padding: 10px;
  /* margin-left: 50px; */
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #1e3a8a;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-button:hover {
  background-color: azure;
  color: #22024a;
}

.dropdown-selected {
  flex-grow: 1;
  text-align: left; 
}

.dropdown-icon {
  position: relative;
  top: 2px;
}

.dropdown-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.dropdown-content {
  background-color: #f0f4ff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 80vw;
  /* 80% of the viewport width */
  height: 90vh;
  /* 80% of the viewport height */
  padding: 16px;
}


/* Popup Header */
.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0rem;
}

/* Close Button */
.dropdown-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
}

.dropdown-close:hover {
  color: #1e3a8a;
}


/* Search Box */
.dropdown-search {
  position: relative;
  width: 100%;
  max-width: calc(100% - 32px);
  /* Ensures it fits inside the popup */
  margin: 0 auto 5px;
  display: flex;
  align-items: center;
}

.search-box {
  width: 100%;
  padding: .5rem 2.5rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* Prevents overflow */
}

.search-box:focus {
  border-color: #1e3a8a;
  outline: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Search Icon */
.search-icon {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 18px;
}

/* Alphabet tabs */
.dropdown-alphabet {
  display: flex;
  justify-content: center;
  gap: 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0px;
}

.alphabet-btn {
  background: transparent;
  border: none;
  padding: .5rem .5rem;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #1e3a8a;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
}

.alphabet-btn:hover {
  color: #0f172a;
}

.alphabet-btn.active {
  color: #0f172a;
  border-bottom: 3px solid #1e3a8a;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  max-height: 75vh;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
}

.dropdown-item {
  padding: 12px;
  background: white;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdown-item:hover {
  background: #e5e7eb;
}

/* Large screens (desktops): 6 columns */
@media (max-width: 1439px) {
  .dropdown-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* Medium screens (tablets): 4 columns */
@media (max-width: 1279px) {
  .dropdown-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Small screens (mobile & portrait tablets): 2 columns */
@media (max-width: 767px) {
  .dropdown-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .dropdown-content {
    width: 95vw;
    height: 85vh;
  }

  .dropdown-item {
    padding: 10px;
    font-size: 14px;
  }

  .dropdown-alphabet {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 4px;
    overflow-x: visible;
  }

  .alphabet-btn {
    min-width: 28px;
    text-align: center;
    padding: 0.4rem 0.5rem;
    font-size: 12px;
  }
}