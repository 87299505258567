.editor-heading{
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f9fa;
}
/* Toolbar Styling */
.toolbar {
  background: #f3f3f3;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 8px 15px;
  margin-bottom: 15px;
  display: flex ;
  gap: 12px;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.toolbar input[type="text"] {
  border: 1px solid #c1c1c1;
  border-radius: 2px;
  padding: 6px 12px;
  font-size: 14px;
  width: 200px;
  background: white;
  transition: border-color 0.2s;
}

.toolbar input[type="text"]:focus {
  border-color: #2B579A;
  outline: none;
}

.toolbar select {
  border: 1px solid #c1c1c1;
  background: white;
  padding: 6px 24px 6px 8px;
  font-size: 14px;
  border-radius: 3px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11%203H1l5%205%205-5z%22%20fill%3D%22%23666%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  transition: border-color 0.2s;
}
.slogan{
  color: rgb(0, 0, 0);
  text-align: center;
}
.slogan1{
  color: rgb(0, 0, 0);
  text-align: center;
 font-family:initial;
}
.abt {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  background-color: #6e6d6d;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 5vw;
  top: 3vh;
}

.abt:hover {
  background-color: #000000;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.modal-content {
  max-width: 1000px;
  max-height: 100vh; /* Adjust this based on your preference */
  overflow-y: auto; /* Enables vertical scrolling */
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #f1f1f1;
  border-bottom: 1px solid #ddd;
}
.modal-header h2 {
  margin-top: 0 !important;
}

.modal-body {
  height: 70vh;
  overflow-y: scroll;
  padding: 0 20px;
}

.close-btn {
  cursor: pointer;
  width: 25px;
  height: auto;
  transition: background-color 0.3s;
}

/* .close-btn:hover {
  color: #fff;
  background-color: #554242;
} */

.modal-content h2 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.modal-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.modal-content ul li {
  margin-bottom: 10px;
  font-size: 1em;
  color: #555;
}

.modal-content p {
  margin-top: 20px;
  font-size: 1em;
  color: #666;
}

.icon {
  font-size: 20px; /* Adjust icon size */
}

.toolbar select:hover {
  border-color: #888;
}

.toolbar select:focus {
  border-color: #2B579A;
  outline: none;
}

/* Updated CSS */
#color-picker {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
  gap: 10px;
}

#color-picker input[type="color"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.color-display {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
}


/* Buttons */
.icon-button {
  background: #2B579A;
  color: white;
  border: 1px solid #244c8c;
  padding: 6px 12px;
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background 0.2s;
}

.icon-button:hover {
  background: #244c8c;
}

/* Editor Styling */
.ql-toolbar {
  background: #f3f3f3;
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px 4px 0 0;
  padding: 8px !important;
}

.ql-container {
  border-top: none !important;
  border-radius: 0 0 4px 4px;
  background: white;
  min-height: 297mm;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.ql-editor {
  font-family: 'Calibri', 'Segoe UI', system-ui, sans-serif;
  font-size: 11pt;
  line-height: 1.5;
  padding: 72px 96px !important;
}

/* Page Simulation */
.ql-editor {
  background: white;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
  min-height: 297mm;
  margin: 20px auto;
  width: 210mm;
}



/* Input field for document name */
.toolbar input[type="text"] {
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  text-align: center;
}

/* Dropdown styling for font and size */
.toolbar select {
  padding: 8px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Color picker styling */
.color-picker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.color-picker input {
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Icon buttons for save and print */
.icon-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e7e6e0;
  color: black;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon-button:hover {
  background-color: #e7e5de;
  transform: scale(1.05);
}

/* Fixing the Quill editor */
.ql-container {
  background: white;
  color: black;
  min-height: 400px !important;
  max-height: 600px !important;
  /* border-radius: 5px; */
  padding: 10px;
  overflow-y: auto !important;
  resize: vertical;
}

/* Ensuring the editor height stays fixed */
.ql-editor {
  font-size: 16px;
  min-height: 400px !important; /* Fixed min height */
  max-height: 600px !important; /* Fixed max height */
  overflow-y: auto !important;  /* Enables scrolling */
}

/* Ensuring toolbar styling */
.ql-toolbar {
  background: #f7f7f7;
  border-radius: 5px 5px 0 0;
}

/* Ensuring images resize properly inside the editor */
.ql-editor img {
  max-width: 100% !important;
  height: auto !important;
  object-fit: contain;
}


.editor{
    background-color: #f7f7f7;
}

.search-bar {
    margin: 10px 0;
}
  .search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-bar {
   width: 500px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

.toggle-button {
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
}

/* editorStyles.css */
.inputapi-transliterate-indic-suggestion-menu {
  position: absolute;
  z-index: 1000;
  background-color: white;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  max-height: 150px;
  /* overflow-y: auto; */
}

.inputapi-transliterate-indic-suggestion-menu-vertical {
  display: flex;
  flex-direction: column;
}

.inputapi-transliterate-indic-suggestion-menu div {
  padding: 5px 10px;
  cursor: pointer;
}

.inputapi-transliterate-indic-suggestion-menu div:hover {
  background-color: #f0f0f0;
}

/* Ensuring the editor container has an A4 paper size */
.document-wrapper {
  background: rgb(198, 196, 196);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  overflow: auto;
  transition: all 0.3s ease;
}
.ql-container.ql-snow {
  background: rgb(198, 196, 196);
  margin: 20px;
  border: none !important;
  box-shadow: none !important;
}
.document-wrapper .ql-container {
  border: none;
  height: 100%;
}

.document-wrapper .ql-editor {
  min-height: 100%;
  padding: 72px;
  margin: 0 auto;
  background: white;
}



/* Fix the Quill editor to match an A4 sheet */
.ql-container {
  background: white;
  color: black;
  min-height: 297mm !important;
  max-height: auto !important;
  overflow-y: hidden !important;
  resize: none;
}

/* Editor should not exceed A4 dimensions */
.ql-editor {
  font-size: 16px;
  min-height: 297mm !important;
  max-height: 297mm !important;
  overflow: hidden;
  padding: 20px;
}

/* Ensuring content moves to the next page */
.page {
  width: 210mm;
  min-height: 297mm;
  background: white;
  display: block;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Page break styles */
@media print {
  .page {
    page-break-after: always;
  }
}

/* Ensuring images resize properly */
.ql-editor img {
  max-width: 100% !important;
  height: auto !important;
  object-fit: contain;
}

/* editorStyles.css */
.ql-image-resize-handle {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #3366ff;
  border: 1px solid #fff;
}

.ql-image-resize-handle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #fff;
}

/* Extra Tip: Add this CSS for better image alignment */
.ql-editor img {
  max-width: 100%;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}


/* Add to editorStyles.css */
.image-container {
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.image-caption {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 5px 0;
}
.ql-editor img {
  max-width: 100%;
  height: auto;
}

.ql-toolbar {
  border-bottom: 1px solid #ccc;
  position: relative;
  z-index: 10; /* Ensures it remains above the editor */
}

.ql-container {
  min-height: 400px;
  border: 1px solid #ccc;
}

.ql-toolbar.ql-snow {
  position: sticky;
  top: 0;
  background: white;
  z-index: 20;
}
label{
  color: rgb(14, 14, 14);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 8px;
}
#n{
display: none;
}
/* Responsive Design */
@media (max-width: 768px) {
  .editor-heading {
    font-size: 18px;
    padding: 8px;
  }

  .toolbar {
    padding: 6px 10px;
    gap: 8px;
  }

  .toolbar input[type="text"] {
    width: 150px;
    font-size: 13px;
  }

  .search-bar {
    width: 100%;
    max-width: 300px;
  }

  .ql-editor {
    padding: 40px 30px !important;
    width: 100% !important;
    margin: 10px auto;
  }

  .document-wrapper {
    margin: 10px;
  }

  .modal-content {
    width: 95%;
    max-width: none;
  }

  .abt {
    right: 3vw;
    top: 2vh;
    padding: 6px 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
  }

  .toolbar input[type="text"] {
    width: 100%;
    max-width: none;
  }

  .ql-editor {
    padding: 30px 15px !important;
    min-height: 200mm !important;
  }

  .slogan, .slogan1 {
    font-size: 14px;
  }

  .modal-body {
    padding: 0 10px;
    height: 60vh;
  }

  .icon-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  #color-picker {
    font-size: 12px;
  }
}

@media print {
  .toolbar, .abt, .editor-heading {
    display: none !important;
  }
  
  .ql-editor {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    width: 100% !important;
    min-height: auto !important;
  }
  
  .document-wrapper {
    background: none;
    box-shadow: none;
    margin: 0;
  }
}

