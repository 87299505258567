/* Base Styles */
.dialogue-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Highlighted Line */
  .highlighted-line {
    background-color: #000000;
    padding: 10px;
    border-radius: 4px;
    transition: all 0.3s ease;
    font-size: 1.4em;
    box-shadow: 0 4px 8px rgba(108, 105, 105, 0.2);
    transform: scale(0.9);
    margin: 5px 0;
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
    max-width: fit-content;
    display: inline-block;
    z-index: 999;
  }

  .highlighted-line .learner-name {
    color: red !important; /* Red color for learner names */
  }

  .highlighted-line .conversation-text {
    color: white !important;
  }
  min-height: 100vh;
  padding: 20px;
  /* background-color: #b9b5b5; */
  box-sizing: border-box;
  margin-left: 80px;
  position: relative;
}

/* Page Title */
.page-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.btn-cons {
  display: flex;
  gap: 10px; /* Space between buttons */
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Center buttons vertically */
  margin: 10px 0; /* Add some margin for spacing */
}

.btn-up-dw {
  background-color: #3498db; /* Blue background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 20px; /* Rounded corners */
  padding: 10px 15px; /* Padding for button size */
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-up-dw:hover {
  background-color: #2980b9; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.btn-up-dw:disabled {
  background-color: #bdc3c7; /* Gray background for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
  opacity: 0.7; /* Reduce opacity for disabled state */
}

.btn-up-dw:disabled:hover {
  transform: none; /* Disable hover effect for disabled buttons */
}

.btn-up-dw svg {
  font-size: 20px; /* Adjust icon size */
}

/* Go Back Button */
.go-back-button {
  position: absolute;
  top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  right: 1230px;
}

.go-back-button:hover {
  background-color: #0056b3;
}

/* Beta Version Button */
.beta-version {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: #ffcc00;
  color: #333;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  z-index: 1000;
}

.beta-version:hover {
  background-color: #e6b800;
}

/* Conversation Selector */
.conversation-selector {
  width: 200px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ffcc00;
  border-radius: 8px;
  background-color: #fffbe6;
  color: #333;
  margin-bottom: 20px;
  cursor: pointer;
}

.conversation-selector:hover {
  border-color: #ffa500;
}

/* Conversation Block */
.conversation-block {
  width: 90%;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 400px;
  margin-bottom: 20px;
}

.script-text {
  font-family: "Courier New", monospace;
  font-size: 1.5rem;
  color: #34495e;
  line-height: 1.6;
  white-space: pre-wrap;
}

/* Highlighted Line */
.highlighted-line {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin: 5px 0;
}

/* Button Container */
.button-con {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.dialogue-container {
  button {
    background-color: #ffcc00;
  }

  button:hover {
    background-color: #e6b800;
  }
}

/* About Page Button */
.about-page-button {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.about-page-button:hover {
  background-color: #0056b3;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .page-title {
    font-size: 1.2rem;
    margin-top: 30px;
  }
  .dialogue-container {
    margin: 0px;
  }
  .conversation-block {
    max-height: 600px;
    padding: 15px;
  }
  .beta-version {
    font-size: 0.6rem;
  }

  .script-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 1rem;
  }

  .conversation-block {
    max-height: 300px;
    padding: 15px;
  }
  .script-text {
    font-size: 0.9rem;
  }

  .button-con {
    flex-direction: row;
    align-items: center;
  }
}
