.floating-unicode-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1; /* Ensure it stays behind the main content but visible */
  }
  
  .floating-unicode {
    position: absolute;
    font-size: 2rem;
    color: rgba(30, 58, 138, 0.5); /* Increase opacity for better visibility */
    font-weight: bold; /* Make the characters bolder */
    opacity: .3;
  }
  
  main {
    z-index: 1; /* Ensure the main content appears above the background */
  }