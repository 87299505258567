/* General container styling */
#about-page-container {
  padding: 1rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 800px; /* Default size for larger screens */
  margin: 0 auto; /* Center the content */
  background-color: #ffffff; /* White background for a clean look */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border: 1px solid #e0e0e0; /* Light border for better definition */
}

/* Headings */
#about-page-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #4caf50; /* Green for the main heading */
}

#about-page-container h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #2196f3; /* Blue for subheadings */
}

/* Paragraphs */
#about-page-container p {
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: justify;
  color: #333333; /* Dark gray for readability */
}

/* Lists */
#about-page-container ul,
#about-page-container ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

#about-page-container ul li,
#about-page-container ol li {
  margin-bottom: 0.5rem;
  color: #555555; /* Medium gray for list items */
}

/* Links */
#about-page-container a {
  color: #ff5722; /* Orange for links */
  text-decoration: none;
  font-weight: bold;
}

#about-page-container a:hover {
  text-decoration: underline;
  color: #e64a19; /* Darker orange on hover */
}

/* Centered text for specific elements */
#about-page-container p[style*="text-align: center"] {
  text-align: center !important;
  color: #757575; /* Light gray for centered text */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  #about-page-container {
    padding: 1rem; /* Adjusted padding for better spacing */
    max-width: 95%; /* Ensure the container takes up more space */
    width: 95%;
  }

  #about-page-container h1 {
    font-size: 1.4rem; /* Reduced font size for the main heading */
  }

  #about-page-container h2 {
    font-size: 1.2rem; /* Reduced font size for subheadings */
  }

  #about-page-container p {
    font-size: 0.9rem; /* Slightly smaller font size for paragraphs */
  }

  #about-page-container ul,
  #about-page-container ol {
    padding-left: 1rem; /* Adjusted padding for lists */
  }

  #about-page-container ul li,
  #about-page-container ol li {
    margin-bottom: 0.4rem; /* Maintain spacing between list items */
    font-size: 0.9rem; /* Slightly smaller font size for list items */
  }

  #about-page-container a {
    font-size: 0.9rem; /* Slightly smaller font size for links */
  }
}

/* Extra small screens (mobile-first approach) */
@media (max-width: 480px) {
  #about-page-container {
    padding: 0.8rem; /* Reduced padding for smaller screens */
    max-width: 100%; /* Ensure the container takes up full width */
    width: 100%;
  }

  #about-page-container h1 {
    font-size: 1.2rem; /* Smaller font size for the main heading */
  }

  #about-page-container h2 {
    font-size: 1rem; /* Smaller font size for subheadings */
  }

  #about-page-container p {
    font-size: 0.85rem; /* Smaller font size for paragraphs */
  }

  #about-page-container ul,
  #about-page-container ol {
    padding-left: 0.8rem; /* Reduced padding for lists */
  }

  #about-page-container ul li,
  #about-page-container ol li {
    margin-bottom: 0.3rem; /* Maintain spacing between list items */
    font-size: 0.85rem; /* Smaller font size for list items */
  }

  #about-page-container a {
    font-size: 0.85rem; /* Smaller font size for links */
  }
}
